.widget-avail{
  width: 100px;
  //float:left;
  margin: 5px;

}
.widget-side{
  background: @color-blue-600;
  color: #fff;
  height: 100px;
}

.generic-widget{
  width: 100%;
  text-align: center;
  padding: 20px 0;
  color: #fff;
  font-size: 18px;
  margin:10px 0;

}

.complex-widget{
  background: @color-purple-600;
}

.purple-widget{
  background: @color-purple-600;
}

.component-widget{
  background: @color-green-600;
}

.layout-widget{
  background: @color-orange-600;
}

.sortable-container-drop{
  padding-top:30px;
  min-height:200px;
  border: 1px dotted #999;
}

.inner-form{
  text-align: left;
  font-size: 14px;
  width: 90%;
  margin: 0 auto;
}

.dz-default.dz-message{
  color: #333;
}