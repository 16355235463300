.widget-side{
  display: none;
  width:100%;
//  border: 1px solid #b3b3b3;
  text-align:center;
  margin-bottom:10px;
  padding:10px;
  cursor: pointer;
  text-transform: capitalize;
  i{
    font-size: 30px;
    display:block;
  }
}

.tp-widget{
  position:relative;
  .widget-admin{
    display: none;
  }

  .widget-loading{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    text-align:center;
    z-index: 100;
    background: rgba(255,255,255,.6);
  }
}

.widget-sortable{

//  &.hover {
//    //border: 1px dotted #b3b3b3;
//    .widget-front {
//      .widget-helpers {
//
//        display: block;
//
//      }
//    }
//  }

  .widget-front{
    position:relative;

    .widget-helpers{
      display:none;
      position:absolute;
      right:0;
      left:0;
      top: -32px;
      z-index: 1000;
      background: rgba(255,255,255,.6);
      padding:5px 10px;

      &.hover{
        display:block;
      }

      .btn{
        margin:0 10px;
      }
    }




  }
}

.widget-avail{
  .widget-side{
    display: block;
  }

  .widget-front{
    display: none;
    }
}

#widget-wrapper{
  padding:0;
  min-height: 300px;
  width:100%;
  border: 1px dotted #999;

}

#widget-settings-form{
//  margin:30px;
}